import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import GameProfile from "../components/GameProfile";

function Game(props) {
  const { id } = useParams();
  const [game, setGame] = useState([]);
  //console.log(id);
  useEffect(() => {
    //axios.get("http://localhost/Projet-cekankonjou/API-php/get-game.php?id=" + id ).then((result) => setGame(result.data));
    axios
    //http://localhost/Projet-cekankonjou/API-php/get-game.php?id
    //"/API/get-game.php?id=" + id
    .get("/API/get-game.php?id=" + id)
    .then((result) => {
      //console.log(result.data);
      setGame(result.data);
    })
    .catch((error) => console.error("Erreur lors de la récupération du jeu:", error));
  }, [id]);
  //console.log(game);
  return (
    <div className="col-lg-4 col-md-5 col-xs-6 mb-2">
      {(game.length != 0) ? (
        <GameProfile game={game} complete />
      ) : (
        <div className="d-flex col-3 mb-3-justifity-content-center">
          Jeu non trouver!
        </div>
      )}
    </div>
  );
}

export default Game;
